import React from 'react'
import '../styles/style.css'
import LanguageSwitcher from "../components/LanguageSwitcher";
import {useTranslation} from "react-i18next";
import {BsTelephoneFill} from 'react-icons/bs';
import {MdEmail} from 'react-icons/md';
import {Link} from 'react-router-dom';

const TopBar = ({topData}) => {
    const top = topData ?. siteSetting;
    const {t} = useTranslation();

    return (
        <div className='topbar_main'>
            <div className='container-fluid ' align="end">
                <div className='row'>
                    <div className='col-md-12 top_lang'>
                        <ul className='d-flex justify-content-between align-items-center mainInfoDetail'>
                            <li className='infoDetail'>
                                <Link to={
                                    `tel:${
                                        top ?. contact
                                    }`
                                } target="_blank">
                                    <p className='d-flex gap-1 infoIcon'>
                                        <span className=""><BsTelephoneFill/></span>
                                        {
                                        top ?. contact
                                    }</p>
                                </Link>
                                <Link to={
                                    `mailto:${
                                      top ?. email
                                    }`
                                } target="_blank">
                                    <p className='d-flex gap-1 infoIcon'>
                                        <span className=""><MdEmail/></span>
                                        {
                                        top ?. email
                                    }</p>
                                </Link>
                                {/* { t('covid_update') } */} </li>

                            <li className='select_lang'>

                                <LanguageSwitcher/>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default TopBar
